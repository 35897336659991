import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "The Empathy Machine",
  "date": "2016-03-25",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I’ve only started getting into House Of Cards. I know what you’re thinking. I simply never got into the show after its first episode. For a long time I thought the show simply wasn’t for me. When giving it another shot I realized I made a terrible mistake the first time around.`}</p>
    <p>{`I treated it like a regular TV show. Being produced by Netflix, the show is optimized for binge-watching. Thus, the show is afforded a lot more time to set up the story and get you hooked. This idea is also reinforced by the naming convention of the episodes. Each episode is a chapter of a long-standing story, making the first episode of the second season chapter 14.`}</p>
    <p>{`All of that to say I’ve become a big Kevin Spacey fan, so when I saw someone post a link to this interview with Spacey, I immediately clicked the link.`}</p>
    <p>{`It’s a pretty incredible interview, James Harding really is an excellent interviewer. They touch on a bunch of interesting topics, including Spacey’s move to longer form storytelling, the sad state of the news industry and VR, which he describes as the ultimate empathy machine.`}</p>
    <p>{`This interview is really worth 30 mins of your time, so `}<a parentName="p" {...{
        "href": "http://youtu.be/qNTIo45bOu0"
      }}>{`check it out`}</a>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      